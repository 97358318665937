<template>
  <v-container
    id="clienti"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        lg="4"
      >
        <hr>
      </v-col>

      <base-material-card
        icon="mdi-clipboard-text"
        title="Analisi Clienti"
        class="px-5 py-3"
      >
        <v-data-table
          mobile-breakpoint="0"
          fixed-header
          disable-pagination
          hide-default-footer
          dense
          :headers="headers"
          :items="dsTable"
          class="elevation-1"
          @touchstart.native.stop
          @touchmove.native.stop
          @touchend.native.stop
        >
          <template v-slot:item.status="{item}">
            <v-chip
              :color="getColor (item.status)"
              dark
            >
              <v-icon style="font-size:15px; ">
                mdi-chart-line
              </v-icon>
            </v-chip>
          </template>
        </v-data-table>
      </base-material-card>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import datiAgente from '../../../plugins/dati_agente'

  export default {
    name: 'Clienti',
    //
    data () {
      return {
        anno: (new Date().getFullYear()),
        headers: [
          { text: 'status', value: 'status' },
          {
            text: 'CodCli',
            align: 'start',
            sortable: true,
            value: 'sottoconto',
          },
          { text: 'RagSoc', value: 'sot_descrizione' },
          { text: 'Prov ', value: 'provincia_cli_anag' },
          // { text: 'Fatturato ' + (this.anno - 1), value: 'fatt_passato' },
          { text: 'Fatt.', value: 'importo_fatt', align: 'right' },
          // { text: 'Budget', value: 'bdg' },
          // { text: 'Delta Bdg', value: 'deltabdg' },
          // { text: 'Budget Min.', value: 'bdgmin' },
          // { text: 'delta Bdg. Min.', value: 'deltabdgmin' },
          // { text: 'Budget L1.', value: 'bdgl1' },
          // { text: 'delta Bdg. L1.', value: 'deltabdgl1' },
          // { text: 'Budget L2.', value: 'bdgl2' },
          // { text: 'delta Bdg. L1.', value: 'deltabdgl2' },
        ],
        dsTable: [],
      }
    },
    computed: {
      ...mapState(['userinfo']),
      annocorrente: function () {
        return new Date().getFullYear()
      },
      mesecorrente: function () {
        return (1 + new Date().getMonth())
      },
      annomese: function () {
        var mese = this.mesecorrente
        if (this.anno < this.annocorrente) {
          mese = 12
        }
        return this.anno * 100 + mese
      },
      get_userinfo: function () {
        return this.$store.state.userinfo
      },
    },
    mounted () {
      const user = this.get_userinfo
      const codage = user.properties.agente
      const opeCodice = 1
      if (this.$store.state.clidata === null || (this.$store.state.clidata.post.year ?? 0) !== this.anno) {
        datiAgente.getCliData(codage, this.anno, opeCodice)
          .then((response) => {
            return response.json()
          })
          .then((jsonData) => {
            this.$store.commit('storeCliDataMutation', jsonData)
          // console.log('cliData', jsonData)
          })
      }
      var dsTab = this.$store.state.clidata.listaCli
      Object.keys(dsTab).forEach(function (key) {
        dsTab[key].status = dsTab[key].importo_fatt
      })
      this.dsTable = Object.values(dsTab)
    },
    methods: {
      getColor (importoFatt) {
        if (importoFatt > 1300) return 'green'
        else if (importoFatt > 600) return 'orange'
        else return 'red'
      },
    },
  }
</script>
